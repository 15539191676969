import {useRoute, useRouter} from 'vue-router';
import {computed, reactive, ref, watch} from 'vue';
import {
  canDo,
  cmsTypes,
  collectiveTypes,
  enableLearn,
  fnshCateCdDcds,
  fnshCondCdDcds,
  fnshCrtrCdDcds,
  getFinishSymbolType,
  getTutors,
  isEvaluation,
  isReport,
  isProgress,
  isReferenceLearn,
  isSurvey,
  isTodayLearn,
  isTodayScheduled,
  lrnObjTyCds,
} from '@/assets/js/modules/learn/learn-common';
import {useStore} from 'vuex';
import {
  getItem,
  getItems,
  getTargetDate,
  getWeek,
  isSuccess,
  lengthCheck,
  timestampToDateFormat,
  timestampToDatePart,
} from '@/assets/js/util';
import {getFunc, getListFunc} from '@/assets/js/ui.init';
import {ACT_GET_BOARD_LIST} from '@/store/modules/board/board';
import {useAlert} from '@/assets/js/modules/common/alert';
import {ACT_GET_CRSE_DIST_APPLY, ACT_GET_CRSE_FNSH_DIST_LIST,} from '@/store/modules/course/course';
import {
  ACT_GET_LEARN_OBJECT_ADDED,
  ACT_GET_LEARN_OBJECT_MASTER_LIST,
  ACT_GET_LEARN_OBJECT_RESULT,
  ACT_GET_LEARN_OBJECT_RESULT_LIST,
  ACT_GET_LEARN_RESULT_SUMUP,
  ACT_INSERT_LEARN_RESULT,
} from '@/store/modules/learn/learn';
import {getThumbUrl} from '@/assets/js/modules/course/course-common';
import {entranceCloudNowOnCheck} from "@/assets/js/modules/cloud/cloud-common";
import {
  ACT_GET_EVL_SYNC_DT,
  ACT_INSERT_LRN_TY_EVL_DIST
} from '@/store/modules/evaluation/evaluation';
import ApiService from "@/assets/js/api.service";

// LearnMain
export const learnMainSetup = () => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const {showMessage} = useAlert();

  const isMobile = computed(() => store.state.auth.isMobile);
  const learnSn = computed(() => parseInt(route.query.learn || 0));
  const session = computed(() => store.state.auth.session);

  const isInit = ref(false);
  const distCrseSn = computed(() => route.params.distCrseSn);
  // const currentDate = ref(new Date())
  const currentDate = ref(null)
  // 학습 객체 마스터 정보, 추후에 다른곳에서 쓰이면 store에 저장

  const modes = ['home', 'index', 'board', 'timetable', 'reference'];
  const infoModes = [0, 1, 2];

  const modeIdx = computed(() => modes.indexOf(route.params.view));

  const boardTyCdDcd = ref('');

  const applyModal = ref(false);
  const pledge = reactive({
    distCrseSn: '',
    lrnerId:'',
    lrnerNm:''
  });

  const params = reactive({
    distCrseSn : 0,
    crseMstSn : 0,
    classCateSn : 0,
    classCateNm : '',
    mediaCateSn : 0,
    mediaCateNm : '',
    orgnzCdDcd : '',
    orgnzNm : '',
    prvdrSn : 0,
    prvdrNm : '',
    introDivCdDcd : '',
    introDivNm : '',
    crseNm : '',
    crseSumup : '',
    crseDtl : '',
    exptAblty : '',
    crseLvl : '',
    classUseYn : '',
    classCnt : 0,
    bgngDt : 0,
    endDt : 0,
    thumb : '',
    etcThumb : '',
    prevUrl : '',
    crseMlg : 0,
    totLrnPerid : 0,
    totLrnHr : 0,
    fnshCondYn : '', // 수료조건 여부
    rfltRateUseYn : '', // 반영 비율 사용 여부
    fnshCrtrUseYn : '', // 수료 기준 사용 여부
    fnshCrtrScore : 0, // 수료 기준 점수
    lrnTrgtGrpMstSn : 0, // 학습 그룹
    eduTrnctCdDcd : '', // 연수원 DCD
    eduTrnctNm : '', // 연수원
    loc : '', // 장소
    lrnTrgtGrpDtlSn: 0,
    mstThumb: '',
    coldCallUseYn: '',
    lrnPsbltHrDiv: '',
  });

  const resultParam = reactive({
    lrnMapngRsltSumupSn : 0,
    fnshYn : '',
    fnshPrcsRsnCd : '',
    getScore : 0,
    totProgRate : 0,
    rsltYn : '',
    getAblty : '',
    fnshTot: 0,
  });

  const learnObjects = ref([]);
  const finishes = ref([]);
  const objectResults = ref([]);

  // const normalObjects = ref([]);
  const referenceObjects = ref([]);
  const boardObjects = ref([]);


  const collectiveCnt = computed(() => objectResults.value.filter(x => collectiveTypes.includes(x.lrnObjTyCd)).length);
  // const boardCnt = computed(() => boardObjects.value.length);

  const referenceLearnObjectTypes = [
    lrnObjTyCds.Certificate,
    lrnObjTyCds.RefBoard,
    lrnObjTyCds.RefEvaluation,
    lrnObjTyCds.RefFinish,
    lrnObjTyCds.Custom,
    lrnObjTyCds.Book
  ];

  const boardLearnObjectTypes = [
      lrnObjTyCds.Board
  ];

  const exclusiveLearnObjectTypes = [
    lrnObjTyCds.SelectionSurvey
  ];



  const statuses = computed(() => {
    const statusItems = [];
    finishes.value.forEach(f => {
      if(f.fnshCrtrCdDcd === fnshCrtrCdDcds.Normal){
        if(f.fnshCondValue > 0){
          if(f.fnshCateCdDcd === fnshCateCdDcds.Evaluation){
            statusItems.push({
              ...f,
              objects: objectResults.value.filter(r => getRelObjects(f.fnshCateCdDcd, r) && r.evlTyCdDcd === f.evlTyCdDcd)
            });
          }else{
            statusItems.push({
              ...f,
              objects: objectResults.value.filter(r => getRelObjects(f.fnshCateCdDcd, r))
            });
          }
        }
        if (f.fnshTot > 0) {
          if (f.fnshCateCdDcd === fnshCateCdDcds.Progress) {
            resultParam.fnshTot = f.fnshTot;
          }
        }
      }else if(f.fnshCrtrCdDcd === fnshCrtrCdDcds.Special){
        statusItems.push({
          ...f,
          objects: objectResults.value.filter(r => r.lrnObjTyCd === f.lrnObjTyCd)
        });
      }
    });
    return statusItems;
  });

  const mainId = computed(() => {
    if(route.params.view === 'home' || route.params.view === 'index' || route.params.view === 'board'){
      return 'kb-myclass-home';
    }
    else if(route.params.view === 'timetable'){
      return 'kb-myclass-schedule';
    }
    else if(route.params.view === 'reference'){
      return 'kb-myclass-requirement';
    }
    return '';
  });

  const getRelObjects = (fnshCateCdDcd, result) => {
    const lrnObjTyCds = learnObjects.value.filter(x => x.fnshCateCdDcd === fnshCateCdDcd).map(x => x.lrnObjTyCd);
    return lrnObjTyCds.includes(result.lrnObjTyCd);
  }

  const setLrnTyEvlDistSn = ({lrnTyEvlDistSn, index}) => {
    objectResults.value[index].lrnTyEvlDistSn = lrnTyEvlDistSn;
  }

  const setLrnRsltSn = ({lrnRsltSn, index}) => {
    objectResults.value[index].lrnRsltSn = lrnRsltSn;
  }

  const initLearnMetaList = async () => {
    await store.dispatch(`evaluation/${ACT_GET_EVL_SYNC_DT}`, {
    }).then(res => {
      if(isSuccess(res)){
        currentDate.value = new Date(res.srvSyncDt);
      }
    });
    await getFunc(`course/${ACT_GET_CRSE_DIST_APPLY}`, distCrseSn.value, params, () => {
      if(params.lrnTrgtGrpDtlSn > 0){
        store.dispatch(`learn/${ACT_GET_LEARN_OBJECT_RESULT_LIST}`, distCrseSn.value).then(response => {
          if (lengthCheck(response)) {
            const items = getItems(response);
            // objectResults.value = items.filter(x => x.lrnObjTyCd !== lrnObjTyCds.SelectionSurvey);
            objectResults.value = items.filter(x =>
                !referenceLearnObjectTypes.includes(x.lrnObjTyCd) &&
                !boardLearnObjectTypes.includes(x.lrnObjTyCd) &&
                !exclusiveLearnObjectTypes.includes(x.lrnObjTyCd)
            );

            referenceObjects.value = items.filter(x => referenceLearnObjectTypes.includes(x.lrnObjTyCd));
            boardObjects.value = items.filter(x => boardLearnObjectTypes.includes(x.lrnObjTyCd));

            if(learnSn.value > 0){
              const target = objectResults.value.map((x, i) => ({...x, objectIndex: i})).find(x => learnSn.value === x.lrnObjDtlDistSn);
              if(target) {
                if (cmsTypes.includes(target.lrnObjTyCd)) {
                  if (enableLearn(params.lrnPsbltHrDiv)) openLearn({index: target.objectIndex, finishYn: target.fnshYn});
                } else {
                  openLearn({index: target.objectIndex, finishYn: target.fnshYn});
                }
              }
            }
          }
          isInit.value = true;
        });

        getFunc(`learn/${ACT_GET_LEARN_RESULT_SUMUP}`, distCrseSn.value, resultParam);
        getListFunc(`learn/${ACT_GET_LEARN_OBJECT_MASTER_LIST}`, null, learnObjects);
        getListFunc(`course/${ACT_GET_CRSE_FNSH_DIST_LIST}`, distCrseSn.value, finishes);
      }else{
        showMessage({
          title: '학습정보없음',
          text: '입과되지 않는 연수입니다.'
        });
        router.push({name: 'TrainProceeding'})
      }
    });
  }

  // 해당 객체 재갱신
  const getLearnObjectResult = () => {
    if(targetIndex.value === -1) return;
    store.dispatch(`learn/${ACT_GET_LEARN_OBJECT_RESULT}`, objectResults.value[targetIndex.value].lrnObjDtlDistSn).then(res => {
      if(lengthCheck(res)){
        objectResults.value[targetIndex.value] = getItem(res);
      }
    });
  }


  // 학습 창을 위한 모듈
  const modalActive = computed(() => route.query.modal);
  const windowActive = ref(false);
  const windowOptions = reactive({
    answer: false
  });
  const isLoading = ref(false);
  const targetIndex = ref(-1);
  const targetItem = ref({});
  const targetAdded = ref({});
  const isVacation = ref(false);

  const modalTitle = computed(() => {
    if (route.query.modal && targetItem.value && targetItem.value.lrnObjDtlDistSn > 0) {
      return targetItem.value.objNm;
    }
    return '학습홈';
  });


  const getLearnType = (target) => {
    if(target && target.lrnObjDtlDistSn > 0){
      if(isProgress(target.lrnObjTyCd)){
        return 'cms';
      }else if(isEvaluation(target.lrnObjTyCd)){
        return 'evl';
      }else if(isSurvey(target.lrnObjTyCd)){
        return 'svy';
      }else if(isReport(target.lrnObjTyCd)){
        return 'rpt';
      }else if(target.lrnObjTyCd === lrnObjTyCds.Collective){
        // if(target.fnshYn === 'Y' && target.objSn > 0){
        if(target.objSn > 0 && currentDate.value > target.objEndDt){
          return 'svy';
        }else{
          return 'ftf';
        }
      }
    }
    return '';
  };

  const targetLearnType = computed(() => getLearnType(targetItem.value));


  const getObjectAdded = (callback) => {
    store.dispatch(`learn/${ACT_GET_LEARN_OBJECT_ADDED}`, targetItem.value.lrnObjDtlDistSn).then(res => {
      if(lengthCheck(res)){
        // 학습 결과도 같이 넣어준다.
        const added = getItem(res);
        added.lrnRsltSn = targetItem.value.lrnRsltSn;
        targetAdded.value = added;
        if(callback instanceof Function) callback();
        windowActive.value = true;
        if (isMobile.value) router.push({query: {modal: targetLearnType.value}});

      } else {
        showMessage('학습자료가 없습니다.')
      }
      isLoading.value = false;
    }).catch(e => {
      console.error(e);
      isLoading.value = false;
    })
  }

  const getDistObjParams = (target) => {
    const params = {lrnObjDtlDistSn: target.lrnObjDtlDistSn};
    if(isSurvey(target.lrnObjTyCd)){
      params.distObjSn = target.distSvySn;
      params.distObjNm = target.svyNm;
    }else if(isProgress(target.lrnObjTyCd)){
      params.distObjSn = target.objSn;
      params.distObjNm = target.objNm;
    }else if(isEvaluation(target.lrnObjTyCd)){
      params.distObjSn = target.lrnTyEvlDistSn;
      params.distObjNm = target.lrnEvlNm;
    }
    return params;
  }

  const enterNowClass = (item) => {
    const params2 = {
      distCrseSn : params.distCrseSn,
      objDistSn: item.lrnObjDtlDistSn,
      lrnObjDtlDistSn: item.lrnObjDtlDistSn,
      objType: item.lrnObjTyCd,
      lrnerId: session.value.lrnerId,
      etnRefDtl: item.etnRefDtl
    }
    if (item.pledgeYn === "Y" && item.pledgeRsltYn != "Y") {
      pledge.distCrseSn = params.distCrseSn;
      pledge.lrnerId = session.value.lrnerId;
      pledge.lrnerNm = session.value.lrnerNm;
      applyModal.value = true;
    } else {
      entranceCloudNowOnCheck(params2);
    }
  }

  watch(() => applyModal.value, () => {
    if (applyModal.value === false) {
      location.reload();
    }
  })

  const checkVacation = () =>{
    ApiService.get('/v1/app/auth/vacation').then(res => {
      if(lengthCheck(res)) {
        if(getItem(res) > 0){
          isVacation.value = true;
        } else {
          isVacation.value = false;
        }
      }
    });
  }
  checkVacation();

  const openLearn = ({index, finishYn}) => {
    // 02 => 근무시간내
    // 03 => 근무시간외
    // cms 타입일 경우 학습 가능시간 정책 적용
    if (cmsTypes.includes(objectResults.value[index].lrnObjTyCd)) if (!isVacation.value && !enableLearn(params.lrnPsbltHrDiv, true)) return;

    if (objectResults.value[index].lrnObjTyCd === '100008' || objectResults.value[index].lrnObjTyCd === '100026' || objectResults.value[index].lrnObjTyCd === '100020') {
      enterNowClass(objectResults.value[index]);
    } else if (collectiveTypes.includes(objectResults.value[index].lrnObjTyCd)) {
      if (objectResults.value[index].svySubmitYn === 'Y') {
        openResultLearnWindow(index);
      } else {
        openLearnWindow(index);
      }
    } else if (isReport(objectResults.value[index].lrnObjTyCd)) {
      //레포트
      openLearnWindow(index);

    } else if (isEvaluation(objectResults.value[index].lrnObjTyCd)) {
      if(finishYn === 'Y'){
        if (getShowAnsProc(objectResults.value[index])) {
          openResultLearnWindow(index);
        } else {
          showMessage(getShowAnsMessage(objectResults.value[index]));
        }
      }else {
        const basProgRate = objectResults.value[index].evlProgRate;
        const status = statuses.value.filter(x => x.fnshCateCdDcd === '2046001');
        if (basProgRate > 0 && status.length === 0) {
          showMessage('진도율 수료기준이 존재하지 않습니다.<br/>관리자에게 문의해 주세요');
          return false;
        } else {
          const progRate = getCurrentValue(status[0]);
          if (basProgRate > progRate) {
            showMessage(`평가가능 진도율을 충족하지 않습니다.<br/><br/><span class="text-primary">${progRate}%</span><span class="text"> / ${basProgRate}%</span>`);
            return false;
          }
        }
        let basTime = objectResults.value[index].evlBgngDt === null ? objectResults.value[index].objBgngDt : objectResults.value[index].evlBgngDt;
        if (currentDate.value.getTime() >= basTime) {
          if (objectResults.value[index].evlBgngCtrlYn === 'Y') {
            showMessage('관리자제어 중입니다.<br/>관리자에게 문의해주세요');
            return false;
          } else {
            openLearnWindow(index);
          }
        } else {
          showMessage('평가응시 가능시간이 아닙니다.');
        }
      }

    } else {
      if(finishYn === 'Y'){
        openResultLearnWindow(index);
      }else{
        openLearnWindow(index);
      }
    }
  }

  const openLearnWindow = async (index, callback) => {
    if(isLoading.value) return;
    isLoading.value = true;
    const target = objectResults.value[index];
    targetIndex.value = index;
    // 윈도우 옵션들 초기화
    Object.keys(windowOptions).forEach(k => {windowOptions[k] = false;})
    if (target && target.lrnTyEvlDistSn === 0 && target.lrnObjTyCd === '100005') {
      await store.dispatch(`evaluation/${ACT_GET_EVL_SYNC_DT}`, {
      }).then(res => {
        if(isSuccess(res)){
          currentDate.value = new Date(res.srvSyncDt);
        }
      });
      await store.dispatch(`evaluation/${ACT_INSERT_LRN_TY_EVL_DIST}`, {
        distCrseSn: distCrseSn.value,
        lrnEvlMstSn: target.objSn
      }).then(res => {
        if(isSuccess(res)){
          setLrnTyEvlDistSn({lrnTyEvlDistSn : res.lrnTyEvlDistSn, index})
        }
      })
    }
    if(target && target.lrnObjDtlDistSn > 0){
      if(target.lrnRsltSn > 0){
        targetItem.value = target;
        getObjectAdded(callback);
      }else{
        await store.dispatch(`learn/${ACT_INSERT_LEARN_RESULT}`, getDistObjParams(target)).then(res => {
          if(isSuccess(res) && res.lrnRsltSn > 0){
            setLrnRsltSn({lrnRsltSn : res.lrnRsltSn, index})
            // 적용 확인...
            target.lrnRsltSn = res.lrnRsltSn;
            targetItem.value = target;
            getObjectAdded();
          }else{
            isLoading.value = false;
          }
        }).catch(e => {
          console.error(e);
          isLoading.value = false;
        });

      }
    }else{
      targetIndex.value = -1;
      isLoading.value = false;
    }
  }

  const openResultLearnWindow = (index) => {
    // 진도율의 경우 그대로 Cms 창을 오픈하다.
    if(isProgress(objectResults.value[index].lrnObjTyCd) && canDo(objectResults.value[index], currentDate.value)){
      openLearnWindow(index);
    }else if(isEvaluation(objectResults.value[index].lrnObjTyCd)){
      openLearnWindow(index, () => {windowOptions.answer = (targetAdded.value && targetAdded.value.evlScoreRlsYn === 'Y');});
    }else if(isReport(objectResults.value[index].lrnObjTyCd)){
      openLearnWindow(index);
    }else if(collectiveTypes.includes(objectResults.value[index].lrnObjTyCd) && objectResults.value[index].svySubmitYn === 'Y'){
      showMessage({
        title: '참여완료',
        text: '이미 설문에 참여하셨습니다.'
      });
    }else if(isSurvey(objectResults.value[index].lrnObjTyCd)){
      showMessage({
        title: '참여완료',
        text: '이미 설문에 참여하셨습니다.'
      });
    }
  }

  const reloadObject = () => {
    getLearnObjectResult();
  }

  const renewStartDt = (evlBgngDt) => {
    targetAdded.value.evlExamBgngDt = evlBgngDt;
  }

  const renewEndDt = () => {
    targetAdded.value.evlExamEndDt = new Date().getTime();
    windowActive.value = false;
    getLearnObjectResult();
  }

  const goTrainMain = () => {
    router.push({name: 'TrainProceeding'});
  }

  const add = (accumulator, a) => {
    return accumulator + a;
  }

  const getCurrentValue = (item) => {
    if (item === null || item === undefined) return 0;
    const useItems =  item.objects.filter(x => x.fnshCond === 'Y');
    if(useItems.length > 0){
      const percent = (useItems.filter(x => x.fnshYn === 'Y').map(x => (x.crtrValue || 0)).reduce(add, 0))/useItems.length;
      return percent >= 95 ? 100 : Math.ceil(percent);
    }
    return 0;
  }

  const getShowAnsProc = (item) => {
    const crctAnsRlsProc = item['crctAnsRlsProc'] ? item['crctAnsRlsProc'].split(',') : '';
    if (isNaN(parseInt(crctAnsRlsProc[1])) || isNaN(parseInt(crctAnsRlsProc[2]))) {
      return false;
    } else if (currentDate.value.getTime() >= (item[crctAnsRlsProc[0]] + (parseInt(crctAnsRlsProc[1]) * 1000 * 60)) &&
               currentDate.value.getTime() <= (crctAnsRlsProc[2] === '0' ? currentDate.value.getTime() :
                                                                           (item[crctAnsRlsProc[0]] + (parseInt(crctAnsRlsProc[1]) * 1000 * 60) +
                                                                                                      (parseInt(crctAnsRlsProc[2]) * 1000 * 60)))) {
      return true;
    }
    return false;
  }

  const getShowAnsMessage = (item) => {
    const crctAnsRlsProc = item['crctAnsRlsProc'] ? item['crctAnsRlsProc'].split(',') : '';
    let msg = ''
    if (!getShowAnsProc(item)) {
      if (isNaN(parseInt(crctAnsRlsProc[1])) || isNaN(parseInt(crctAnsRlsProc[2]))) {
        msg = '답안지는 비공개 입니다.'
      } else {
        if (parseInt(crctAnsRlsProc[2]) === 0) {
          msg = '답안지 공개시간은<br>'.concat(timestampToDateFormat(item[crctAnsRlsProc[0]] + (parseInt(crctAnsRlsProc[1]) * 1000 * 60), 'yyyy.MM.dd hh:mm:ss'),' 이후부터 상시공개됩니다.');
        } else {
          msg = '답안지 공개기간은<br>'.concat(
            timestampToDateFormat(item[crctAnsRlsProc[0]] + (parseInt(crctAnsRlsProc[1]) * 1000 * 60), 'yyyy.MM.dd hh:mm:ss'), '~',
            timestampToDateFormat(item[crctAnsRlsProc[0]] + (parseInt(crctAnsRlsProc[1]) * 1000 * 60) + (parseInt(crctAnsRlsProc[2]) * 1000 * 60), 'yyyy.MM.dd hh:mm:ss'),'<br>기간동안만 공개됩니다.');
        }
      }
    }
    return msg;
  }

  initLearnMetaList();

  watch(() => route.params, async () => {
    // if(route.name === 'LearnMain') {
      await store.dispatch(`evaluation/${ACT_GET_EVL_SYNC_DT}`, {
      }).then(res => {
        if(isSuccess(res)){
          currentDate.value = new Date(res.srvSyncDt);
        }
      });
    // }
  });

  watch(() => distCrseSn.value, () => {
      if(route.name == 'LearnMain'){
        initLearnMetaList();
      }
  })

  return {
    isInit,
    currentDate,
    infoModes,
    modeIdx,
    params,
    resultParam,
    objectResults,
    referenceObjects,
    boardObjects,
    collectiveCnt,
    mainId,
    statuses,

    setLrnRsltSn,
    getLearnObjectResult,
    targetIndex,
    targetItem,
    targetAdded,
    targetLearnType,
    windowActive,
    windowOptions,
    renewStartDt,
    renewEndDt,
    reloadObject,
    openLearn,
    goTrainMain,
    // 학습창 - 모바일
    modalActive,
    modalTitle,
    boardTyCdDcd,
    applyModal,
    pledge
  }
}

export const learnRouteSetup = (props) => {
  const route = useRoute();
  const distCrseSn = computed(() => route.params.distCrseSn);
  const learnView = computed(() => route.params.view)
  const learnMenus = computed(() => {
    const routes = [
      {
        name: '홈',
        path : '/learn/'+distCrseSn.value+'/home',
        view: 'home'
      },
      {
        name: '학습목차',
        path : '/learn/'+distCrseSn.value+'/index',
        view: 'index'
      }
    ];

    if(props.boardObjects && props.boardObjects.length > 0){
      routes.push({
        name: '게시판',
        path : '/learn/'+distCrseSn.value+'/board/' + props.boardObjects[0].lrnObjDtlDistSn,
        view: 'board'
      });
    }

    if(props.collectiveCnt > 0){
      routes.push({
        name: '시간표',
        path : '/learn/'+distCrseSn.value+'/timetable',
        view: 'timetable'
      });
    }

    if(props.referenceCnt > 0) {
      routes.push({
        name: '참조수료조건',
        path : '/learn/'+distCrseSn.value+'/reference',
        view: 'reference'
      });
    }

    return routes;
  });
  return {
    learnView,
    learnMenus
  }
}

export const learnScheduledSetup = (props, {emit}) => {
  const scheduled = computed(() => {
    // return props.items.map((x,index) => ({...x, originIdx: index})).filter(x => x.fnshYn !== 'Y' && isTodayScheduled(x, props.currentDate));
    // 오늘 예정된 학습(객체타입): 평가(100005), 비대면NOW(100008)
    return props.items.map((x,index) => ({...x, originIdx: index})).filter(x => (x.fnshYn !== 'Y' && isTodayScheduled(x, props.currentDate)) && ((x.lrnObjTyCd === '100005' || x.lrnObjTyCd === '100006' || x.lrnObjTyCd === '100008')));
  });

  const getClassNow = (start, end) => {
    const nowTs = props.currentDate.getTime();
    return nowTs >= start && nowTs < end;
  };

  const openLearn = (item) => {
    // if(item.lrnObjTyCd === '100008' || item.lrnObjTyCd === '100020'){  // 클라우드 나우, 온 입장
    //   enterNowClass(item);
    // }else{
    if (getClassNow(item.objBgngDt, item.objEndDt)) {
      emit('openLearn', {index: item.originIdx, finishYn: item.fnshYn});
    }
    // }
  }

  const store = useStore();
  const route = useRoute();
  const session = computed(() => store.state.auth.session);
  const distCrseSn = computed(() => route.params.distCrseSn);

  const applyModal = ref(false);

  const pledge = reactive({
    distCrseSn: '',
    lrnerId:'',
    lrnerNm:''
  });
  const enterNowClass = (item) => {
    const params = {
      distCrseSn : distCrseSn.value,
      objDistSn: item.lrnObjDtlDistSn,
      lrnObjDtlDistSn: item.lrnObjDtlDistSn,
      objType: item.lrnObjTyCd,
      lrnerId: session.value.lrnerId,
      etnRefDtl: item.etnRefDtl
    }
    if (item.pledgeYn === "Y" && item.pledgeRsltYn != "Y") {
      pledge.distCrseSn = distCrseSn.value;
      pledge.lrnerId = session.value.lrnerId;
      pledge.lrnerNm = session.value.lrnerNm;
      applyModal.value = true;
    } else {
      entranceCloudNowOnCheck(params);
    }
  }

  const receiveMessage = (e) => {
    if(e.data.code == 'reloadUrl'){
      location.reload();
    }
  }

  watch(() => applyModal.value, () => {
    if (applyModal.value === false) {
      location.reload();
    }
  })

  window.addEventListener('message', receiveMessage, false);

  return {
    scheduled,
    getClassNow,
    timestampToDateFormat,
    lrnObjTyCds,
    openLearn,
    enterNowClass,
    applyModal,
    pledge
  }

}

export const learnStatusSetup = () => {
  const getTargetName = (item) => {
    if(item.fnshCrtrCdDcd === fnshCrtrCdDcds.Normal){
      return item.fnshCateNm;
    }else if(item.fnshCrtrCdDcd === fnshCrtrCdDcds.Special){
      return item.lrnObjTyNm;
    }
    return '';
  }

  const getTargetUnit = (fnshCondNm) => {
    if(fnshCondNm && fnshCondNm.length > 0){
      return fnshCondNm.substr(0, 1);
    }
    return '';
  }

  const add = (accumulator, a) => {
    return accumulator + a;
  }

  const isAvg = [fnshCondCdDcds.Percentage, fnshCondCdDcds.Score];

  const checkUseObjects = (item) => {
    return item.objects && item.objects.filter(x => x.fnshCond === 'Y').length > 0;
  }

  const getCurrentValue = (item) => {
    if(isAvg.includes(item.fnshCondCdDcd)){
      const useItems =  item.objects.filter(x => x.fnshCond === 'Y');
      if(useItems.length > 0){
        const percent = (useItems.filter(x => x.fnshYn === 'Y').map(x => (x.crtrValue || 0)).reduce(add, 0))/useItems.length;
        if (item.lrnObjTyCd === '100005') {
          return percent;
        } else {
          return percent >= 95 ? 100 : Math.ceil(percent);
        }
      }
      return 0;
    }
    return item.objects.map(x => x.crtrValue).reduce(add, 0);
  }

  const getUseObjectCount = (item) => {
    return item.objects.filter(x => x.fnshCond === 'Y').length;
  }


  const getTotCount = (item) => {
    // if(isAvg.includes(item.fnshCondCdDcd)){
    //   return 100;
    // }
    return item.fnshCondValue;
  }

  return {
    getTargetName,
    getFinishSymbolType,
    getTargetUnit,
    checkUseObjects,
    getCurrentValue,
    getUseObjectCount,
    getTotCount,
    fnshCateCdDcds
  }
}

export const learnRefSetup = (props) => {
  const route = useRoute();
  const router = useRouter();

  const items = ref([]);
  const boardTyCdDcd = computed(() => {
    if(props.refMode === 'notice'){
      return '2033001'
    }else if(props.refMode === 'lib'){
      return '2033002'
    }
    return ''
  });

  const currentDate = new Date();
  const isNewIcon = (regDt) => {
    return (currentDate.getTime() - regDt) < 1000 * 60 * 60 * 24 * 7
  }

  const distCrseSn = computed(() => route.params.distCrseSn || 0);

  const goList = () => {
    router.push({name: props.listRouteName, params: {mode: props.refMode}, query: {distCrseSn: distCrseSn.value}})
  }

  getListFunc(`board/${ACT_GET_BOARD_LIST}`, {
    distCrseSn: distCrseSn.value,
    boardTyCdDcd: boardTyCdDcd.value,
    pageSize: 3
  }, items);


  return {
    items,
    boardTyCdDcd,
    goList,
    isNewIcon,
    timestampToDateFormat
  }
}

// LearnIndex

export const learnIndexSetup = (props, {emit}) => {
  const tab = ref('today');
  const tabToggle = ref(false);
  // const currentDateStr = computed(() => timestampToDateFormat(props.currentDate.value.getTime(), 'full-kr'));
  const currentDateStr = ref(0);

  const progressTypes = [lrnObjTyCds.MP4, lrnObjTyCds.MP3, lrnObjTyCds.PDF, lrnObjTyCds.Youtube, lrnObjTyCds.HTML, lrnObjTyCds.Tool];
  const collectiveTypes = [lrnObjTyCds.Collective, lrnObjTyCds.CloudNow, lrnObjTyCds.CloudOn, lrnObjTyCds.CloudNowLive];
  const reportTypes = [lrnObjTyCds.Report];
  const evaluationTypes = [lrnObjTyCds.Evaluation];
  const surveyTypes = [lrnObjTyCds.Survey];
  const boardTypes = [lrnObjTyCds.Board, lrnObjTyCds.LearnBoard];

  const renderCount = computed(() => props.items.filter(x => !isReferenceLearn(x.lrnObjTyCd) && isLearnObjectShow(x)).length);
  const evlTestCount = computed(() => props.items.filter(x => !isReferenceLearn(x.lrnObjTyCd) && isLearnObjectShow(x) && x.objSn === 4888).length);

  const isLearnObjectShow = (item) => {
    if(tab.value === 'today'){
      return isTodayLearn(item, props.currentDate);
    }
    return true;
  }

  const selectTab = (selected) => {
    tab.value = selected;
  }

  const getObjectDt = (item, division) => {
    // if(evaluationTypes.includes(item.lrnObjTyCd)){
    //   return timestampToDateFormat(item[`evl${division}Dt`], 'yyyy.MM.dd hh:mm');
    // }else if(surveyTypes.includes(item.lrnObjTyCd)){
    //   return timestampToDateFormat(item[`svy${division}Dt`], 'yyyy.MM.dd hh:mm');
    // }
    return timestampToDateFormat(item[`obj${division}Dt`], 'yyyy.MM.dd hh:mm');
  }

  const getSymbolType = (lrnObjTyCd) => {
    if(progressTypes.includes(lrnObjTyCd)) {
      return 'symbol-type-contents';
    } else if(surveyTypes.includes(lrnObjTyCd)) {
      return 'symbol-type-survey';
    } else if(evaluationTypes.includes(lrnObjTyCd)) {
      return 'symbol-type-evaluation';
    } else if(collectiveTypes.includes(lrnObjTyCd)) {
      return 'symbol-type-gathering'
    } else if(reportTypes.includes(lrnObjTyCd)) {
      return 'symbol-type-report';
    } else if(boardTypes.includes(lrnObjTyCd)) {
      return 'symbol-type-social';
    }
    return '';
  };

  const getButtonText = (item) => {

    let text = ''

    if(progressTypes.includes(item.lrnObjTyCd)) {
      text = '학습';
    }else if(surveyTypes.includes(item.lrnObjTyCd)) {
      text = '참여';
    } else if(evaluationTypes.includes(item.lrnObjTyCd)) {
      text = '응시';
    } else if(collectiveTypes.includes(item.lrnObjTyCd)) {
      text = '참여'
    } else if(reportTypes.includes(item.lrnObjTyCd)) {
      text = '제출';
    } else if(boardTypes.includes(item.lrnObjTyCd)) {
      text = '작성';
    }
    // 아직 종료일 전이다.
    if(item.fnshYn === 'Y'){
      text += '완료';
    }else{
      if(item.objEndDt > 0 && item.objBgngDt > 0){
        // 집합 객체의 경우 설문이 달려 있다면 예외 케이스 처리한다.
        if (collectiveTypes.includes(item.lrnObjTyCd) && item.distSvySn > 0 && props.currentDate.getTime() > item.objEndDt) return (text + '하기');

        if(props.currentDate.getTime() > item.objEndDt){
          return '종료';
        }
      }else{
        // 객체의 학습기간이 없으면 차수의 학습기간으로 적용
        if(props.currentDate.getTime() > item.endDt){
          return '종료';
        }
      }
      // }
      text += '하기';
    }
    return text;
  }

  const getScoreUnit = (item, coldCallUseYn) => {
    if(progressTypes.includes(item.lrnObjTyCd)) {
      let score = ['100001'].includes(item.lrnObjTyCd) ? `${item.crtrValue >= 95 ? 100 : Math.ceil(item.crtrValue || 0)}%` : '-';
      if (coldCallUseYn === 'Y' && ['100001'].includes(item.lrnObjTyCd)) {
        // score += `<br>(${item.coldCallRspnsDt > 0 ? '응답' : '미응답'} ${item.coldCallRspnsDt > 0 ? 1 : 0} / ${item.coldCallRound})`
        score += `<br>(${item.coldCallRspnsDt > 0 ? '응답' : '미응답'} ${item.coldCallRspCnt} / ${item.coldCallRound})`
      }
      return score;
    } else if(surveyTypes.includes(item.lrnObjTyCd)) {
      if(item.fnshYn === 'Y') return '참여완료';
    } else if(evaluationTypes.includes(item.lrnObjTyCd)) {
      if (getShowScoreProc(item)) {
        return item.fnshYn === 'Y' ? `${item.crtrValue}점` : '-';
      } else {
        return '-';
      }
    } else if(collectiveTypes.includes(item.lrnObjTyCd)) {
      if(item.fnshYn === 'Y') return '출석';
    } else if(reportTypes.includes(item.lrnObjTyCd)) {
      if(item.fnshYn === 'Y'){
        return '제출';
      } else{
        return '미제출';
      }
    }

    return '';
  }

  const openLearnWindow = (index, finishYn) => {
    // let item = props.items[index];
    // if(item.lrnObjTyCd === '100008' || item.lrnObjTyCd === '100020'){  // 클라우드 나우, 온 입장
    //   enterNowClass(item);
    // }else{
    emit('openLearn', {index, finishYn})
    // }
  }

  const getAddedMetas = (item) => {
    const metas = [];
    if (item.chapNm) {
      metas.push(item.chapNm);
    }
    const tutor = getTutors(item);
    if (tutor) {
      metas.push(tutor);
    }
    return metas;
  }

  const getRangeDate = (item) => {
    const bgngDt = getObjectDt(item, 'Bgng');
    const endDt = getObjectDt(item, 'End');

    if (bgngDt && endDt) {
      return `${bgngDt} ~ ${endDt}`;
    }
    return null;
  }

  const renderItems = computed(() => props.items.map(x => ({...x, addedMetas: getAddedMetas(x), rangeDate: getRangeDate(x)})))


  const store = useStore();
  const route = useRoute();
  const session = computed(() => store.state.auth.session);
  const distCrseSn = computed(() => route.params.distCrseSn);

  const applyModal = ref(false);

  const pledge = reactive({
    distCrseSn: '',
    lrnerId:'',
    lrnerNm:''
  });

  const enterNowClass = (item) => {
    const params = {
      distCrseSn : distCrseSn.value,
      objDistSn: item.lrnObjDtlDistSn,
      lrnObjDtlDistSn: item.lrnObjDtlDistSn,
      objType: item.lrnObjTyCd,
      lrnerId: session.value.lrnerId,
      etnRefDtl: item.etnRefDtl
    }
    if (item.pledgeYn === "Y" && item.pledgeRsltYn != "Y") {
      pledge.distCrseSn = distCrseSn.value;
      pledge.lrnerId = session.value.lrnerId;
      pledge.lrnerNm = session.value.lrnerNm;
      applyModal.value = true;
    } else {
      entranceCloudNowOnCheck(params);
    }
  }

  const receiveMessage = (e) => {
    if(e.data.code == 'reloadUrl'){
      location.reload();
    }
  }
  window.addEventListener('message', receiveMessage, false);

  watch(() => applyModal.value, () => {
    if (applyModal.value === false) {
      location.reload();
    }
  })

  const initDt = async () => {
    await store.dispatch(`evaluation/${ACT_GET_EVL_SYNC_DT}`, {
    }).then(res => {
      if(isSuccess(res)){
        currentDateStr.value = res.srvSyncDt;
      }
    });
  }

  const getShowScoreProc = (item) => {
    const evlScoreRlsProc = item['evlScoreRlsProc'] ? item['evlScoreRlsProc'].split(',') : '';
    if (isNaN(parseInt(evlScoreRlsProc[1])) || isNaN(parseInt(evlScoreRlsProc[2]))) {
      return false;
    } else if (props.currentDate.getTime() >= (item[evlScoreRlsProc[0]] + (parseInt(evlScoreRlsProc[1]) * 1000 * 60)) &&
               props.currentDate.getTime() <= (evlScoreRlsProc[2] === '0' ? props.currentDate.getTime() :
                                                                            (item[evlScoreRlsProc[0]] + (parseInt(evlScoreRlsProc[1]) * 1000 * 60) +
                                                                                                        (parseInt(evlScoreRlsProc[2]) * 1000 * 60)))) {
      return true;
    }
    return false;
  }

  initDt();

  return {
    tab,
    tabToggle,
    currentDateStr,
    renderCount,
    renderItems,
    isReferenceLearn,
    isLearnObjectShow,
    getSymbolType,
    selectTab,
    canDo,
    getButtonText,
    getScoreUnit,
    getTutors,
    getObjectDt,
    openLearnWindow,
    timestampToDateFormat,
    lrnObjTyCds,
    enterNowClass,
    evlTestCount,
    pledge,
    applyModal
  }
}

export const learnTimetableSetup = (props) => {
  const store = useStore();
  const isMobile = computed(() => store.state.auth.isMobile);
  const currentDate = new Date();
  const today = reactive({
    year: currentDate.getFullYear(),
    month: (currentDate.getMonth() + 1),
    day: currentDate.getDate(),
  });

  const selectedDate = reactive({
    year: currentDate.getFullYear(),
    month: (currentDate.getMonth() + 1),
    day: currentDate.getDate()
  });

  const currentWeekNumber = computed(() => weekNumberByFnc(selectedDate));
  const targetWeek = computed(() => getWeek(selectedDate.year, selectedDate.month, selectedDate.day));

  const collectiveTypes = [lrnObjTyCds.Collective, lrnObjTyCds.CloudNow, lrnObjTyCds.CloudOn, lrnObjTyCds.CloudNowLive];
  const cloudTypes = [lrnObjTyCds.CloudNow, lrnObjTyCds.CloudOn];

  const filteredItems = computed(() => {
    const firstFiltered = props.items
    .filter(x => (collectiveTypes.includes(x.lrnObjTyCd)))
    .map(x => ({...x, startPart: timestampToDatePart(x.objBgngDt), endPart: timestampToDatePart(x.objEndDt)}));

    if (isMobile.value) {
      return firstFiltered.filter(x => x.startPart.ymd === x.endPart.ymd);
    }
    return firstFiltered.filter(x => x.startPart.ymd === x.endPart.ymd && x.startPart.hh > 8 && x.endPart.hh < 20);
  });

  // PC 의 경우 1주일 데이터를 select, Mobile 일 경우는 선택된 날짜 select
  const renderItems = computed(() => {
    if (isMobile.value) {
      const targetItems = filteredItems.value.filter(x => (x.startPart.year === selectedDate.year && x.startPart.month === selectedDate.month && x.startPart.day === selectedDate.day));
      const result = [];
      for(let i = 1; i <= 10; i++) {
        const startHour = (i + 8);
        const endHour = startHour + 1;
        const filtered = targetItems.filter(x => x.startPart.hh <= startHour && x.endPart.hh >= (endHour + 1));
        result.push({
          hour: (`0${startHour}`).slice(-2),
          // objNm: filtered.length > 0 ? filtered[0].objNm + (filtered.length > 1 ? `외 ${filtered.length - 1} 과정` : '') : '-',
          objNm: filtered.length > 0 ? filtered[0].objNm : '-',
          tutors: filtered.length > 0 ? getTutors(filtered[0], null) : null,
          icon: filtered.length > 0 ? getScheduleIcon(filtered[0]) : null,
        })
      }
      return result;
    } else {
      return filteredItems.value
      .filter(x => {
        if(targetWeek.value.length === 7){
          const startYmd = targetWeek.value[0].ymd;
          const endYmd = targetWeek.value[6].ymd;
          const targetYmd = timestampToDatePart(x.objBgngDt).ymd;
          return startYmd <= targetYmd && endYmd >= targetYmd;
        }
        return false;
      });
    }


  });

  const getScheduleClasses = (item) => {
    const rowspan = item.endPart.min === '00' ? 0 : 1;
    return [`track-${(item.startPart.w + 1)}`, `time-${('0' + item.startPart.hh).slice(-2)}00`, `rowspan-${(item.endPart.hh - item.startPart.hh + rowspan)}`, `session-type-${cloudTypes.includes(item.lrnObjTyCd) ? '1': '0'}`]
  }

  const getScheduleTime = (item) => {
    return `${('0' + item.startPart.hh).slice(-2)}:${item.startPart.min}-${('0' + item.endPart.hh).slice(-2)}:${item.endPart.min}`;
  }

  const getScheduleIcon = (item) => {
    if(item.lrnObjTyCd === lrnObjTyCds.Collective){
      return isMobile.value ? 'icon-in-person' : 'icon-meet';
    }else if(item.lrnObjTyCd === lrnObjTyCds.CloudNow){
      return 'icon-check';
    }else if(item.lrnObjTyCd === lrnObjTyCds.CloudOn){
      return 'icon-list';
    }
    return '';
  }


  const weekNumberByFnc = ({year, month, day}) => {
    const firstDate = new Date(year, month, 1);
    const lastDate = new Date(year, month+1, 0);

    const firstDayOfWeek = firstDate.getDay() === 0 ? 7 : firstDate.getDay();
    const lastDayOfWeek = lastDate.getDay();

    // 인풋한 달의 마지막 일
    const lastDay = lastDate.getDate();
    // 첫 날의 요일이 월, 화, 수 이라면 true
    const firstWeekCheck = firstDayOfWeek === 5 || firstDayOfWeek === 6 || firstDayOfWeek === 7;
    // 마지막 날의 요일이 목, 금, 토라면 true
    const lastWeekCheck = lastDayOfWeek === 1 || lastDayOfWeek === 2 || lastDayOfWeek === 3;
    // 해당 달이 총 몇주까지 있는지
    const lastWeekNo = Math.ceil((firstDayOfWeek - 1 + lastDay) / 7);
    // 날짜 기준으로 몇주차 인지
    const weekNo = Math.ceil((firstDayOfWeek - 1 + day) / 7);
    // 인풋한 날짜가 첫 주에 있고 첫 날이 월, 화, 수로 시작한다면 'prev'(전달 마지막 주)
    if(weekNo === 1 && firstWeekCheck) {
      const afterDate = new Date((month === 1 ? year - 1 : year), (month === 1 ? 12 : month - 1), 0);
      return weekNumberByFnc({
        year: afterDate.getFullYear(),
        month: (afterDate.getMonth() + 1),
        day: afterDate.getDate()
      });
    }
    else if(weekNo === lastWeekNo && lastWeekCheck) {
      // return 1;
      return {month: month, weekNo: 1};
    }
    else if(firstWeekCheck) {
      return {month: month, weekNo: weekNo -1};
    }
    return {month: month, weekNo: weekNo};
  };


  const diffWeek = 1000 * 60 * 60 * 24 * 7;

  const moveWeek = (isNext) => {
    const tDate = getTargetDate(selectedDate.year, selectedDate.month, selectedDate.day);
    const resultDate = new Date(isNext ? tDate.getTime() + diffWeek : tDate.getTime() - diffWeek);
    selectedDate.year = resultDate.getFullYear();
    selectedDate.month = (resultDate.getMonth() + 1);
    selectedDate.day = resultDate.getDate();
  }

  const iconDescActive = ref(false);
  const iconDesc = ref('');

  const selectDate = date => {
    selectedDate.year = date.year;
    selectedDate.month = date.month;
    selectedDate.day = date.day;
  }

  const showIconDesc = (text) => {
    iconDescActive.value = true;
    iconDesc.value = text;
  }

  const hideIconDesc = () => {
    iconDescActive.value = false;
  }

  return {
    renderItems,
    today,
    selectedDate,
    targetWeek,
    currentWeekNumber,
    moveWeek,
    selectDate,
    getScheduleClasses,
    getScheduleTime,
    getScheduleIcon,
    getTutors,
    iconDescActive,
    iconDesc,
    showIconDesc,
    hideIconDesc
  }
}

export const learnInfoSetup = (props) => {
  const finishText = computed(() => {
    if (props.params.splCrseTyCdDcd === '2066013' || props.params.splCrseTyCdDcd === '2066014') {
      return props.params.sumupFnshYn === 'Y' ? '<span class="text-primary">완료</span>' : '<span class="text-muted">미완료</span>';
      // if (props.learns.length > 0) {
      //   if (props.params.coldCallUseYn === 'Y') {
      //     return props.learns.filter(x => x.totProgRate >= 95 && (x.maxPlaySec < 60 || x.coldCallRspnsDt > 0)).length === props.learns.length ?  '<span class="text-primary">완료</span>' : '<span class="text-muted">미완료</span>';
      //   }
      //   return props.learns.filter(x => x.totProgRate >= 95).length === props.learns.length ? '<span class="text-primary">완료</span>' : '<span class="text-muted">미완료</span>';
      // }
    }
    return '';
  });

  return {
    finishText,
    getThumbUrl,
    timestampToDateFormat
  }
}
